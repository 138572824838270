/* eslint-disable @typescript-eslint/no-explicit-any */
import { authGetWithHeader, PrevAxios, getCookie, authGet, authPost, authPut, type AxiosResponse, getToken } from '@prev-front/api-adapter'

import { type CasosInterface } from './types'
import { API_HOST_DASHBOARD_V2, API_HOST_DECISOES_V1, REACT_APP_MAPBOX_ACCESS_TOKEN, IAPI_HOST_V3 } from "./constants"
import QueryString from 'qs'
import { JurisprudenciaCase } from '../types/types'
interface getPetitionsParams {
  params?: any
}

export const getPetitions = async ({
  params = 'status=REVISANDO&ordenaPor=SCORE&size=3'
}: getPetitionsParams): Promise<any> => {
  return await authGetWithHeader(`/peticoes?${params}`)
}

export const getPetitionFull = async ({
  id
}: {
  id: string
}): Promise<any> => {
  return await authGetWithHeader(`/peticoes/${id}`)
}

interface GetCLientPetitionProps {
  id: string
}

interface GetCLientPetitionResponse {
  id: string
  idDoModeloDaPeticao: string
  idDoCliente: string
  cpfDoCliente: string
  idDoEscritorio: number
  titulo: string
  resumo: string
  tipo: string
  tipoDeAcao: string
  tags: string[]
  criadoPor: string
  atualizadoPor: string
  dataDeCriacao: string
  dataDaUltimaAtualizacao: string
  sexo: 'MASCULINO' | 'FEMININO' // Assumindo que são as únicas opções
  tipoDeProcesso: string
  tiposDeBeneficio: string[]
  conteudo: string
}

export const getClientPetition = async (props: GetCLientPetitionProps): Promise<GetCLientPetitionResponse> => {
  const { id } = props
  const response = await authGet(`/clientes-peticoes/${id}`, API_HOST_DASHBOARD_V2)
  return response as GetCLientPetitionResponse
}


export const getDecision = async (id): Promise<any> => {
  return await authGetWithHeader(`/search/decisoes/${id}`, IAPI_HOST_V3)
}

interface GetCasosResponse {
  data: CasosInterface[]
}

export const getCasos = async (params: string): Promise<GetCasosResponse> => {
  const token = getToken()
  const cookie = getCookie('prev_session')
  let prev_session = '' as any
  if (cookie) prev_session = { 'X-Global-Session-User-ID': cookie }
  const data = await PrevAxios({
    method: 'GET',
    url: `${API_HOST_DASHBOARD_V2}/casos?${params}`,
    headers: {
      Authorization: `Bearer ${token}`,
      ...prev_session
    }
  })

  console.log('DATA', data)

  return data
}

export const getCasoFull = async (id: string) => {
  const token = getToken()
  const cookie = getCookie('prev_session')
  let prev_session = '' as any
  let captchaToken = '' as any
  if (cookie) prev_session = { 'X-Global-Session-User-ID': cookie }
  if (token) captchaToken = { 'g-recaptcha-response': token }
  const data = await PrevAxios({
    method: 'GET',
    url: `${API_HOST_DASHBOARD_V2}/casos/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      ...prev_session
    }
  })
  return data.data as CasosInterface
}

interface ReserveCaseInterface {
  id: string
  description: string
}


export const reserveCase = async (props: ReserveCaseInterface): Promise<any> => {
  const cookie = getCookie('prev_session')
  let prev_session = '' as any
  if (cookie) prev_session = { 'X-Global-Session-User-ID': cookie }
  const headers = {
    'Content-Type': 'text/html',
    ...prev_session
  }
  const response = await authPost({
    data: props.description,
    host: API_HOST_DASHBOARD_V2,
    path: `/casos/${props.id}/reservas`,
    headers
  })
  return response
}
interface PostPetitionEvaluationInterface {
  curtiu: boolean
  peticaoId: string
  opcoes?: string[]
}

export const postPetitionEvaluation = async (payload: PostPetitionEvaluationInterface): Promise<void> => {
  await authPost({
    data: {
      ...payload,
      opcoes: []
    },
    path: '/peticoes/avaliacoes'
  })
}

export interface GetClientSearchResponse {
  id: number
  uuid: string
  idDoEscritprio: number
  nome: string
  cpf: string
  sexo: 'MASCULINO' | 'FEMININO'
  dataDeNascimento: string
  dataDaUltimaAtualizacao: string
  dataDeCriacao: string
}

export const getClientSearch = async (query: string): Promise<GetClientSearchResponse[]> => {
  const data = await authGet(`/clientes?q=${query}`, API_HOST_DASHBOARD_V2)
  return data as GetClientSearchResponse[]
}

interface PutClientPetitionProps {
  cpfDoCliente: string
  idDaPeticao: string
  idDoEscritorio: number
  novoConteudo: string
}

export const putClientPetition = async (props: PutClientPetitionProps): Promise<GetCLientPetitionResponse> => {
  const { cpfDoCliente, idDaPeticao, idDoEscritorio, novoConteudo } = props
  const response = await authPut({
    data: {
      cpfDoCliente,
      idDaPeticao,
      idDoEscritorio,
      novoConteudo
    },
    path: '/utils/peticoes/converter'
  })
  return response as GetCLientPetitionResponse
}

export const saveClientPetition = async (petition: any): Promise<any> => {
  const response = await authPut({
    data: petition,
    path: `/clientes-peticoes/${petition.id}`,
    host: API_HOST_DASHBOARD_V2
  })
  return response
}


export const findCoordinatesByZip = async (zipcode: string): Promise<any> => {
  const data = await PrevAxios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/&types=postcode&country=BR&postcode=${zipcode}.json?access_token=${REACT_APP_MAPBOX_ACCESS_TOKEN}`)
  return data
}

export const getCidades = async (value: string): Promise<any> => {
  const data = await authGet(`/cidades?nome=${value}`)
  return data
}

interface GetClientsProps {
  limite: number
  ordenaPor: string
  pagina: number
  token: string
}

export const getClients = async (props: GetClientsProps): Promise<any> => {
  const { limite, ordenaPor, pagina } = props
  const response = await authGetWithHeader(
    `/clientes?limite=${limite}&ordenaPor=${ordenaPor}&pagina=${pagina}`, API_HOST_DASHBOARD_V2
  )
  return response as AxiosResponse
}

export const createClient = async (payload: NewClient): Promise<any> => {
  const response = await authPost({
    path: '/clientes',
    data: {
      ...payload
    },
    host: API_HOST_DASHBOARD_V2
  })
  return response
}

export interface Profissao {
  codigo: string
  titulo: string
  condicoesGeraisDoExercicio: string
  descricaoSumaria: string
}

export const getEnderecoByCep = async (cep): Promise<any> => {
  return await authGet(`/utils/cep/${cep}`)
}

export interface NewClient {
  nome: string
  cpf: string
  estadoCivil: 'SOLTEIRO' | 'CASADO' | 'VIUVO' | 'DIVORCIADO' | 'SEPARADO'
  sexo: 'MASCULINO' | 'FEMININO'
  dataDeNascimento: string
  fichaDeAtendimento?: AttendanceSheet
  profissoes?: Profissao[]
  email?: string
  telefonePrincipal?: string
  celular?: string
  telefoneSecundario?: string
  endereco?: Endereco
  escolaridade?: 'ENSINO_FUNDAMENTAL_INCOMPLETO' | 'ENSINO_FUNDAMENTAL_COMPLETO' | 'ENSINO_MEDIO_INCOMPLETO' | 'ENSINO_MEDIO_COMPLETO' | 'ENSINO_SUPERIOR_INCOMPLETO' | 'ENSINO_SUPERIOR_COMPLETO' // Adicione ou remova conforme necessário
  ctps?: string
  nit?: string
  dataDeObito?: string
  rg?: string
  cnh?: string
  nitSecundario?: string
}

export interface AttendanceSheet {
  id: number
  uuid: string
  nasceuZonaRuralEconomiaFamiliar: boolean
  totalDeHectares: number
  totalDeEmpregados: number
  serviuExercito: boolean
  fezEscolaTecnica: boolean
  foiJovemAprendiz: boolean
  exerceuServicoPublicoConcursado: boolean
  teveCertidaoDeTempoDeContribuicao: boolean
  teveContratoDeExperiencia: boolean
  teveRiscoDeSaudeOuIntegridadeFisica: boolean
  descricaoRiscoDeSaudeOuIntegridadeFisica: string
  algumColegaDeTrabalhoReconheceuAtividadeEspecial: boolean
  foiAutonomoOuContribuinteIndividual: boolean
  teveProcessoJudicialTrabalhista: boolean
  teveProcessoJudicialPrevidenciario: boolean
  recebeuOuPediuBeneficio: boolean
  temCopiaDoProcessoAdministrativo: boolean
  trabalhouForaDoBrasil: boolean
  recolheuGPS: boolean
  trabalhouComoPessoaComDeficiencia: boolean
  sofreOuSofreuDoencaQueDificultaTrabalhar: boolean
  responsaveis: string[]
}

interface Endereco {
  cep: string
  endereco: string
  cidade: string
  complemento: string
  uf: string
  bairro: string
  numero: string
}

export interface JobsInterface {
  codigo: string
  titulo: string
  sinonimos: string[]
  condicoesGeraisDoExercicio: string
  descricaoSumaria: string
}

type Params = Record<string, any>

export async function getJobs (params: Params): Promise<JobsInterface[] | null> {
  try {
    const queryString = QueryString.stringify(params, { addQueryPrefix: true, skipNulls: true })
    const response = await authGet(`/profissoes${queryString}`, API_HOST_DASHBOARD_V2)
    if (response) {
      return response as JobsInterface[]
    } else {
      throw new Error('Resposta vazia do servidor.')
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getOfficeInfo = async (): Promise<any> => {
  return await authGet("/escritorios/info")
}

export interface MetaLaudos {
  alerts: string[];
  warnings: string[];
  precision: number;
  page: {
    current: number;
    total_pages: number;
    total_results: number;
    size: number;
  };
  request_id: string;
  max_score: number | null;
}

export interface LaudoResponseInterface {
  empresa: {
    raw: string;
  };
  cnpj?: {
    raw: string | null;
  };
  tipoDeLaudo: {
    raw: string;
  };
  endereco?: {
    raw: string;
  };
  cidade: {
    raw: string;
  };
  uf: {
    raw: string;
  };
  dataDoLaudo: {
    raw: string;
  };
  setores?: {
    setor?: {
      raw: string[];
    };
    atividades?: {
      raw: string[];
    };
    agentesInsalubres?: {
      raw: string[];
    };
    tiposDeAgentesInsalubres?: {
      raw: string[];
    };
    ocupacoes: {
      ocupacao: {
        raw: string[];
      };
      sinonimos?: {
        raw: string[];
      };
    };
  };
  resumo: {
    raw: string;
  };
  cnae?: {
    descricao: {
      raw: string[];
    };
  };
  dataDeCriacao: {
    raw: string;
  };
  _meta: {
    score: number | null;
    id: string;
  };
  id: {
    raw: string;
  };
}

export interface LaudosListInterface {
  meta: MetaLaudos;
  results: LaudoResponseInterface[];
}

export interface OrdenacaoLaudosInterface {
  ordenaPor: string
  tipoDeOrdenacao: string
}

export const getLaudos = async (filters: any[], ordenacao: OrdenacaoLaudosInterface): Promise<LaudosListInterface> => {
  console.log("filtros:", filters);
  console.log("ord:", ordenacao);
  const buildQueryParams = (filters: any[]) => {
    const params = new URLSearchParams();
    filters.forEach((filter) => {
      Object.keys(filter).forEach((key) => {
        if (filter[key] !== "") {  
          params.append(key, filter[key]);
        }
      });
    });
    return params.toString();
  };
  const filterParams = buildQueryParams(filters);
  const url = `/search/laudos/app-search?size=20&sort=${ordenacao.ordenaPor}%2C${ordenacao.tipoDeOrdenacao.toLocaleLowerCase()}&v=1${filterParams ? `&${filterParams}` : ''}`;
  const data = await authGet(url, IAPI_HOST_V3);
  return data as LaudosListInterface;
};

export interface GetAllDecisionsResponse {
  results: JurisprudenciaCase[]
  meta: MetaLaudos
}


export const getAllDecisions = async (filters: any[], ordenacao: OrdenacaoLaudosInterface): Promise<GetAllDecisionsResponse> => {
  const buildQueryParams = (filters: any[]) => {
    const params = new URLSearchParams();
    filters.forEach((filter) => {
      Object.keys(filter).forEach((key) => {
        if (filter[key] !== "") {  
          params.append(key, filter[key]);
        }
      });
    });
    return params.toString();
  };
  const filterParams = buildQueryParams(filters);
  const url = `/search/decisoes/app-search?size=20&sort=${ordenacao.ordenaPor}%2C${ordenacao.tipoDeOrdenacao.toLocaleLowerCase()}&v=1${filterParams ? `&${filterParams}` : ''}`;
  const data = await authGet(url, IAPI_HOST_V3);
  return data as GetAllDecisionsResponse;
};

export const downloadLaudo = async (id: string, captchaToken?: string): Promise<any> => {
  console.log("access_token_prev", captchaToken)
  const data = await authGet(
    `/search/laudos/${id}/download`,
    IAPI_HOST_V3,
    {
      headers: {
        'g-recaptcha-response': captchaToken,
        'Content-Type': 'application/pdf',
      },
      responseType: 'blob',
    }
  )
  return data
}

export const downloadDecisao = async (id: string, captchaToken?: string): Promise<any> => {
  console.log("access_token_prev", captchaToken)
  const data = await authGet(
    `/search/decisoes/${id}/download`,
    IAPI_HOST_V3,
    {
      headers: {
        'g-recaptcha-response': captchaToken,
        'Content-Type': 'application/pdf',
      },
      responseType: 'blob',
    }
  )
  return data
}
