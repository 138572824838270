import {
  Col,
  Row,
  Text,
  Button,
  Tabs,
  Progress,
  Form,
  usePrevTheme,
  SearchContext
} from '@prev-front/ui-components'
import React, { useContext, useEffect, useState } from 'react'
import {
  ClockCircleOutlined,
  SendOutlined,
  StarOutlined
} from '@ant-design/icons'
import { PersonalData } from './tabs/PersonalData'
import { AnimationHandlerContext } from '../../context/AnimationHandlerContext'
import { type CasosInterface } from '../../services/types'
import { getCasoFull, reserveCase } from '../../services'
import moment from 'moment'
import { ClientCaseSheet } from './tabs/ClienteCaseSheet'
import { NotificationContext } from '../../context/NotificationContext'
import { UseSearchInterface } from '@prev-front/mf-template'
moment.locale('pt-br')

export interface CasosFormProps {
  mensagemAdvogado: string
}

interface CasosViewProps {
  searchContext?: UseSearchInterface  & { scrollTop: () => void, checkPaywall: (module?:string) => boolean }
}

export const CasosView = (props: CasosViewProps): JSX.Element => {
  const { theme } = usePrevTheme()
  const { casosView } = useContext(AnimationHandlerContext)
  const [loading, setIsLoading] = useState(false)
  const [data, setData] = useState<CasosInterface>()
  const [form] = Form.useForm<CasosFormProps>()
  const { api } = useContext(NotificationContext)
  const fetchCaso = async (): Promise<void> => {
    try {
      if (!casosView.id || !casosView.isVisible) return
      setIsLoading(true)
      const response = await getCasoFull(casosView.id)
      setData(response)
      casosView.setCasosView({
        ...casosView,
        title: response?.questionario.tipoDeAjuda ?? ''
      })
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    void fetchCaso()
  }, [casosView.id])

  const postReseve = async (values: CasosFormProps): Promise<void> => {
    try {
      if (!data?.uuid) throw new Error('Caso não encontrado')
      await reserveCase({
        description: values.mensagemAdvogado,
        id: data?.uuid
      })
      await fetchCaso()
    } catch (err) {
      api.error({
        message: err?.message ?? "Algo deu errado ao reservar caso",
        placement: "topRight"
      })
    }
  }

  return (
    <Row justify="center">
      <Col
        span={22}
        style={{
          backgroundColor: theme.color.surface.default,
          padding: '20px'
        }}
      >
        <Row style={{ justifyContent: 'space-between', marginBottom: '10px' }}>
          <Col>
            <Text style={{ marginBottom: '10px' }} emphasis size="h6">
              {data?.cliente.nome}
            </Text>
            <Text size="caption" style={{ display: 'flex', gap: '5px' }}>
              <ClockCircleOutlined style={{ color: theme.color.icon.info }} />
              <Text
                size="caption"
                color={theme.color.text.subtlest}
                type="span"
              >
                Caso enviado{' '}
                {moment(new Date(data?.dataDeCriacao ?? '')).fromNow()}
              </Text>
            </Text>
          </Col>
          <Row justify="end" align="middle" style={{ gap: '20px' }}>
            {data?.quantidadeMaximaDeReservas !== undefined &&
              data.numeroDeReservas !== undefined && (
                <Row style={{ gap: '10px' }}>
                  <Progress
                    type="circle"
                    percent={
                      (data?.numeroDeReservas /
                        data?.quantidadeMaximaDeReservas) *
                      100
                    }
                    steps={data.quantidadeMaximaDeReservas}
                    strokeColor={
                      data.numeroDeReservas !== data.quantidadeMaximaDeReservas
                        ? 'green'
                        : 'red'
                    }
                    trailColor={theme.color.border.default}
                    size={20}
                  />
                  <Text>
                    {data.numeroDeReservas}/{data.quantidadeMaximaDeReservas}{' '}
                    contatos
                  </Text>
                </Row>
              )}
            <Button type="subtle" icon={<StarOutlined />}>
              Favoritar
            </Button>
            {!data?.reservado && (
              <Button
                type="primary"
                disabled={
                  !(data?.quantidadeMaximaDeReservas !== data?.numeroDeReservas)
                }
                style={{ color: 'white' }}
                icon={<SendOutlined />}
                onClick={() => {
                  const ok = props.searchContext?.checkPaywall()
                  if(ok) {
                    form.submit()
                  }
                }}
              >
                Contatar cliente
              </Button>
            )}
          </Row>
        </Row>
        <Tabs
          components={[
            {
              title: (
                <Text color={theme.color.text.subtlest}>Dados pessoais</Text>
              ),
              value: (
                <PersonalData
                  data={data}
                  loading={loading}
                  form={form}
                  handleForm={postReseve}
                />
              )
            },
            {
              title: (
                <Text color={theme.color.text.subtlest}>Ficha do cliente</Text>
              ),
              value: <ClientCaseSheet data={data} />
            }
          ]}
        />
      </Col>
    </Row>
  )
}
