export const formatArquiveText = (text: string): string => {
  const fileName = text

  // Defina o número de letras a serem mantidas antes de adicionar "..."
  const letrasParaManter = 45

  // Verifica se o comprimento do nome do arquivo excede o número de letras que você deseja manter
  if (fileName.length > letrasParaManter) {
    // Corta o nome do arquivo para manter apenas as letras desejadas e adiciona "..."
    const novoNomeDoArquivo = fileName.slice(0, letrasParaManter) + '...'
    return novoNomeDoArquivo
  } else {
    return fileName
  }
}

export const cleanObject = (obj: any): any => {
  for (const key in obj) {
    if (obj[key] === undefined || obj[key] === null) {
      delete obj[key]
    } else if (Array.isArray(obj[key])) {
      obj[key] = obj[key].filter((item: any) => {
        if (typeof item === 'object') {
          cleanObject(item)
          return Object.keys(item).length > 0
        }
        return item !== undefined && item !== null && item !== ''
      })
      if (obj[key].length === 0) {
        delete obj[key]
      }
    } else if (typeof obj[key] === 'object') {
      cleanObject(obj[key])
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key]
      }
    }
  }
  return obj
}

export const ajustHifen = (value): string => {
  const cleaned = value.replace(/[^\d]/g, '')

  let formatted = ''

  if (cleaned.length <= 2) {
    formatted = `(${cleaned}`
  } else if (cleaned.length <= 7) {
    formatted = `(${cleaned.slice(0, 2)}) ${cleaned.slice(2)}`
  } else if (cleaned.length <= 10) {
    formatted = `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 6)}-${cleaned.slice(6)}`
  } else {
    formatted = `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 6)}-${cleaned.slice(6, 10)}`
  }

  return formatted
}

export const escolaridade = [
  { value: 'NENHUM', desc: 'Nenhum' },
  {
    value: 'ENSINO_FUNDAMENTAL_INCOMPLETO',
    desc: 'Ensino Fundamental Incompleto'
  },
  { value: 'ENSINO_FUNDAMENTAL_COMPLETO', desc: 'Ensino Fundamental Completo' },
  { value: 'ENSINO_MEDIO_INCOMPLETO', desc: 'Ensino Médio Incompleto' },
  { value: 'ENSINO_MEDIO_COMPLETO', desc: 'Ensino Médio Completo' },
  { value: 'ENSINO_SUPERIOR_INCOMPLETO', desc: 'Ensino Superior Incompleto' },
  { value: 'ENSINO_SUPERIOR_COMPLETO', desc: 'Ensino Superior Completo' },
  { value: 'POS_GRADUADO', desc: 'Pós Graduado' },
  { value: 'MESTRADO', desc: 'Mestrado' },
  { value: 'DOUTORADO', desc: 'Doutorado' },
  { value: 'POS_DOUTORADO', desc: 'Pós Doutorado' }
]

export const estados = [
  { label: 'Nenhum', value: '' },
  { label: 'Acre', value: 'AC' },
  { label: 'Alagoas', value: 'AL' },
  { label: 'Amapá', value: 'AP' },
  { label: 'Amazonas', value: 'AM' },
  { label: 'Bahia', value: 'BA' },
  { label: 'Ceará', value: 'CE' },
  { label: 'Distrito Federal', value: 'DF' },
  { label: 'Espírito Santo', value: 'ES' },
  { label: 'Goiás', value: 'GO' },
  { label: 'Maranhão', value: 'MA' },
  { label: 'Mato Grosso', value: 'MT' },
  { label: 'Mato Grosso do Sul', value: 'MS' },
  { label: 'Minas Gerais', value: 'MG' },
  { label: 'Pará', value: 'PA' },
  { label: 'Paraíba', value: 'PB' },
  { label: 'Paraná', value: 'PR' },
  { label: 'Pernambuco', value: 'PE' },
  { label: 'Piauí', value: 'PI' },
  { label: 'Rio de Janeiro', value: 'RJ' },
  { label: 'Rio Grande do Norte', value: 'RN' },
  { label: 'Rio Grande do Sul', value: 'RS' },
  { label: 'Rondônia', value: 'RO' },
  { label: 'Roraima', value: 'RR' },
  { label: 'Santa Catarina', value: 'SC' },
  { label: 'São Paulo', value: 'SP' },
  { label: 'Sergipe', value: 'SE' },
  { label: 'Tocantins', value: 'TO' }
]


export function simplifyRawProps(obj: any): any {
  // Verifica se o objeto é um objeto real e não um array ou outro tipo de dado
  if (typeof obj === 'object' && obj !== null) {
    // Cria um novo objeto para evitar mutar o original
    const newObj: any = Array.isArray(obj) ? [] : {};
    
    // Itera sobre cada chave do objeto
    for (const key in obj) {
      // Verifica se a chave é "raw"
      if (key === 'raw') {
        return obj[key]; // Retorna o valor de "raw"
      }
      
      // Chama recursivamente para aninhar os objetos, caso existam
      newObj[key] = simplifyRawProps(obj[key]);
    }

    return newObj;
  }

  // Se não for um objeto, retorna o valor diretamente
  return obj;
}

export function extractSnippets(obj: any) {
  let snippets: any = [];

  function recursiveSearch(o) {
    for (const key in o) {
      if (key === 'snippet') {
        snippets.push(o[key]);
      } else if (typeof o[key] === 'object' && o[key] !== null) {
        recursiveSearch(o[key]);
      }
    }
  }

  recursiveSearch(obj);
  return snippets;
}