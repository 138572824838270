export const tiposAjuda = [
  {
    tipo: 'TENHO_UMA_DOENCA',
    desc: 'Tenho uma doença que me impede de trabalhar',
    beneficio: 'Auxílio-Doença',
    tag: 'Benefício por Incapacidade'
  },
  {
    tipo: 'TEMPO_OU_IDADE',
    desc: 'Acho que tenho tempo ou idade para me aposentar',
    beneficio: 'Aposentadoria por tempo de contribuição',
    tag: 'Benefício Programável'
  },
  {
    tipo: 'BENEFICIO_CESSADO',
    desc: 'Eu estava recebendo um benefício, que foi cortado pelo INSS',
    beneficio: 'Restabelecimento',
    tag: 'Restabelecimento'
  },
  {
    tipo: 'BENEFICIO_REVISAO',
    desc: 'Já recebo um benefício do INSS e quero discutir o valor',
    beneficio: 'Revisão',
    tag: 'Revisão'
  },
  {
    tipo: 'FAMILIAR_MORTO_OU_PRESO',
    desc: 'Um familiar foi preso ou faleceu',
    beneficio: 'Auxílio reclusão',
    tag: 'Benefício de terceiro'
  },
  {
    tipo: 'GRAVIDEZ',
    desc: 'Estou grávida, tive filho ou fiz adoção recentemente',
    beneficio: 'Salário maternidade',
    tag: 'Salário maternidade'
  }
]

export const tagsFiltros = [
  {
    tipo: 'TRABALHO_URBANO',
    desc: 'Trabalhei somente na cidade.'
  },
  {
    tipo: 'TRABALHO_RURAL',
    desc: 'Já trabalhei no meio rural.'
  },
  {
    tipo: 'FORCAS_ARMADAS',
    desc: 'Já servi no exército, aeronáutica ou na marinha.'
  },
  {
    tipo: 'SERVICO_PUBLICO',
    desc: 'Já trabalhei no serviço público.'
  },
  {
    tipo: 'RECEBEU_BENEFICIO_INSS_NO_PASSADO',
    desc: 'Já recebi algum benefício do INSS no passado.'
  },
  {
    tipo: 'NAO_CONTRIBUI_HA_MAIS_DE_6_MESES',
    desc: 'Faz mais de 6 meses que não contribuo para o INSS.'
  }
]

export const tags = [
  {
    tipo: 'NAO_CONTRIBUI_HA_MAIS_DE_6_MESES',
    desc: 'Não contribui há mais de 6 meses'
  },
  {
    tipo: 'TRABALHO_RURAL',
    desc: 'Trabalhador rural'
  },
  {
    tipo: 'RECEBEU_BENEFICIO_INSS_NO_PASSADO',
    desc: 'Recebeu benefício INSS no passado'
  },
  {
    tipo: 'FORCAS_ARMADAS',
    desc: 'Forças armadas'
  },
  {
    tipo: 'TRABALHO_URBANO',
    desc: 'Trabalho urbano'
  },
  {
    tipo: 'SERVICO_PUBLICO',
    desc: 'Serviço público'
  },
  {
    tipo: 'TENHO_UMA_DOENCA',
    desc: 'Benefício por Incapacidade'
  },
  {
    tipo: 'TEMPO_OU_IDADE',
    desc: 'Benefício Programável'
  },
  {
    tipo: 'BENEFICIO_CESSADO',
    desc: 'Restabelecimento'
  },
  {
    tipo: 'BENEFICIO_REVISAO',
    desc: 'Revisão'
  },
  {
    tipo: 'FAMILIAR_MORTO_OU_PRESO',
    desc: 'Benefício de terceiro'
  },
  {
    tipo: 'GRAVIDEZ',
    desc: 'Salário maternidade'
  },
  {
    tipo: 'NENHUM',
    desc: 'Nenhum'
  }
]

export const beneficios = [
  {
    tipo: 'APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO',
    desc: 'Aposentadoria por tempo de contribuição.',
    next: ''
  },
  {
    tipo: 'APOSENTADORIA_POR_IDADE',
    desc: 'Aposentadoria por idade.',
    next: ''
  },
  {
    tipo: 'APOSENTADORIA_ESPECIAL',
    desc: 'Aposentadoria especial.',
    next: ''
  },
  {
    tipo: 'AUXILIO_DOENCA',
    desc: 'Auxílio-doença.',
    next: ''
  },
  {
    tipo: 'AUXILIO_ACIDENTE',
    desc: 'Auxílio-acidente.',
    next: ''
  },
  {
    tipo: 'PENSAO_POR_MORTE',
    desc: 'Pensão por morte.',
    next: ''
  },
  {
    tipo: 'AUXILIO_RECLUSAO',
    desc: 'Auxílio-reclusão.',
    next: ''
  },
  {
    tipo: 'SALARIO_MATERNIDADE',
    desc: 'Salário maternidade.',
    next: ''
  },
  {
    tipo: 'BENEFICIO_ASSISTENCIAL',
    desc: 'Benefício assistencial (LOAS).',
    next: ''
  },
  {
    tipo: 'APOSENTADORIA_POR_INVALIDEZ',
    desc: 'Aposentadoria por invalidez.',
    next: ''
  },
  {
    tipo: 'APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_PONTOS',
    desc: 'Aposentadoria por tempo de contribuição pontos.',
    next: ''
  },
  {
    tipo: 'APOSENTADORIA_POR_INCAPACIDADE_PERMANENTE',
    desc: 'Aposentadoria por incapacidade permanente.',
    next: ''
  },
  {
    tipo: 'AUXILIO_INCAPACIDADE_TEMPORARIA_ACIDENTE',
    desc: 'Auxílio por incapacidade temporária acidentário.',
    next: ''
  },
  {
    tipo: 'AUXILIO_INCAPACIDADE_TEMPORARIA',
    desc: 'Auxílio por incapacidade temporária.',
    next: ''
  },
  {
    tipo: 'APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_PROFESSOR',
    desc: 'Aposentadoria por Tempo de Contribuição - Professor.',
    next: ''
  },
  {
    tipo: 'APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_PONTOS_PROFESSOR',
    desc: 'Aposentadoria por Tempo de Contribuição - Professor (Pontos).',
    next: ''
  },
  {
    tipo: 'APOSENTADORIA_POR_IDADE_RURAL',
    desc: 'Aposentadoria por idade (Rural).',
    next: ''
  },
  {
    tipo: 'APOSENTADORIA_RURAL',
    desc: 'Aposentadoria Rural.',
    next: ''
  },
  {
    tipo: 'APOSENTADORIA_POR_IDADE_DEFICIENCIA',
    desc: 'Aposentadoria por Idade da Pessoa com Deficiência.',
    next: ''
  },
  {
    tipo: 'APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_DEFICIENCIA',
    desc: 'Aposentadoria por Tempo de Contribuição da Pessoa com Deficiência.',
    next: ''
  },
  {
    tipo: 'ASSISTENCIAL',
    desc: 'Assistencial.',
    next: ''
  },
  {
    tipo: 'INCAPACIDADE',
    desc: 'Incapacidade.',
    next: ''
  },
  {
    tipo: 'BENEFICIO_ASSISTENCIAL_IDOSO',
    desc: 'Benefício assistencial (LOAS)',
    next: ''
  }
]

export const tiposDoenca = [
  {
    tipo: 'ACIDENTE_DE_TRABALHO',
    desc: 'Sofri um acidente no trabalho e não consigo mais trabalhar',
    next: 'pedidoBeneficio'
  },
  {
    tipo: 'ACIDENTE_DE_TRABALHO_NO_TRAJETO',
    desc: 'Sofri um acidente no trajeto para o trabalho e não consigo mais trabalhar',
    next: 'pedidoBeneficio'
  },
  {
    tipo: 'DOENCA_COMUM',
    desc: 'A doença não tem a ver com o trabalho que eu faço ou fazia, mas mesmo assim hoje não consigo mais trabalhar.',
    next: 'pedidoBeneficio'
  }
]

export const familiar = [
  {
    tipo: 'CONJUGE_OU_COMPANHEIRO',
    desc: 'Esposa(o) ou companheira(o)',
    next: ''
  },
  {
    tipo: 'FILHO',
    desc: 'Filho(a)',
    next: ''
  },
  {
    tipo: 'PAI_OU_MAE',
    desc: 'Pai ou mãe',
    next: ''
  },
  {
    tipo: 'IRMAO',
    desc: 'Irmã(o)',
    next: ''
  }
]

export const historicoOpcoesMulher = [
  {
    tipo: 'TRABALHEI_MAIS_DE_30_ANOS',
    desc: 'Já trabalhei 30 anos ou mais.',
    next: ''
  },
  {
    tipo: 'TRABALHEI_25_ANOS_ATIVIDADE_ESPECIAL',
    desc: 'Trabalhei 25 anos ou mais em atividades insalubres ou perigosas.',
    next: ''
  },
  {
    tipo: 'TRABALHEI_20_ANOS_PROFESSORA',
    desc: 'Trabalhei 20 anos ou mais como Professora.',
    next: ''
  },
  {
    tipo: 'NENHUM',
    desc: 'Não sei dizer se me encaixo nos casos acima.',
    next: ''
  }
]

export const historicoOpcoesHomem = [
  {
    tipo: 'TRABALHEI_MAIS_DE_35_ANOS',
    desc: 'Já trabalhei 35 anos ou mais.',
    next: ''
  },
  {
    tipo: 'TRABALHEI_25_ANOS_ATIVIDADE_ESPECIAL',
    desc: 'Trabalhei 25 anos ou mais em atividades insalubres ou perigosas.',
    next: ''
  },
  {
    tipo: 'TRABALHEI_25_ANOS_PROFESSOR',
    desc: 'Trabalhei 25 anos ou mais como Professora.',
    next: ''
  },
  {
    tipo: 'NENHUM',
    desc: 'Não sei dizer se me encaixo nos casos acima.',
    next: ''
  }
]

export const historicoOutrasOpcoes = [
  {
    tipo: 'TRABALHO_URBANO',
    desc: 'Trabalhei somente na cidade.',
    next: ''
  },
  {
    tipo: 'TRABALHO_RURAL',
    desc: 'Já trabalhei no meio rural.',
    next: ''
  },
  {
    tipo: 'FORCAS_ARMADAS',
    desc: 'Já servi no exército, aeronáutica ou na marinha.',
    next: ''
  },
  {
    tipo: 'SERVICO_PUBLICO',
    desc: 'Já trabalhei no serviço público.',
    next: ''
  },
  {
    tipo: 'RECEBEU_BENEFICIO_INSS_NO_PASSADO',
    desc: 'Já recebi algum benefício do INSS no passado.',
    next: ''
  },
  {
    tipo: 'NAO_CONTRIBUI_HA_MAIS_DE_6_MESES',
    desc: 'Faz mais de 6 meses que não contribuo para o INSS.',
    next: ''
  },
  {
    tipo: 'NENHUM',
    desc: 'Nenhuma opção se aplica para mim.',
    next: ''
  }
]
