/* eslint-disable @typescript-eslint/no-explicit-any */
import { getLaudos, LaudosListInterface, OrdenacaoLaudosInterface } from "../services"

export const useLaudos = () => {
	const fetchLaudos = async (filters: any[], ordenacao: OrdenacaoLaudosInterface): Promise<LaudosListInterface> => {
			const data = await getLaudos(filters, ordenacao)
			return data
	}

	return {
			fetchLaudos
	}
}