/* eslint-disable react/prop-types */
import React, { createContext, useState } from 'react'
import { useSpring, animated, type SpringValue } from 'react-spring'
import { Col, Form, usePrevTheme } from '@prev-front/ui-components'
import { Navbar } from '../components/ui/Navbar'
import { CasosView } from '../components/casosview/CasosView'
import { type NewClient } from '../types/types'
import { CreateClientForm } from '../components/createClient/CreateClientForm'
import { ClientFooter } from '../components/createClient/Footer'
import { tiposAjuda } from '../utils/respostasFicha'
import { UseSearchInterface } from '@prev-front/mf-template'

export interface AnimationHandlerInterface {
  casosView: {
    isVisible: boolean
    id: undefined | string
    setCasosView: (props: CasosViewInterface) => void
    casosViewX: SpringValue<number>
  }
  createClient: {
    createClientX: SpringValue<number>
    isVisibleCreate: boolean
    setIsVisibleCreate: React.Dispatch<React.SetStateAction<boolean>>
  }
}

export interface CasosViewInterface {
  isVisible: boolean
  id: string | undefined
  title: string | undefined
}

export const AnimationHandlerContext = createContext<AnimationHandlerInterface>(
  {} as any
)

interface CasosFrameViewProps {
  children: React.ReactNode
  searchContext?: UseSearchInterface  & { scrollTop: () => void, checkPaywall: () => boolean }
}


export const AnimationHandlerProvider = ({ children, searchContext }: CasosFrameViewProps): JSX.Element => {
  const [casosView, setCasosView] = useState<CasosViewInterface>({
    isVisible: false,
    id: undefined,
    title: undefined
  })

  console.log("searchconte", searchContext)

  const [isVisibleCreate, setIsVisibleCreate] = useState(false)
  const { theme } = usePrevTheme()
  const [newClientForm] = Form.useForm<NewClient>()

  const resizeObserver = new ResizeObserver(() => {
    window.outerWidth = window.innerWidth
  })

  resizeObserver.observe(document.documentElement)

  const { x: casosViewX } = useSpring({
    x: casosView.isVisible ? 0 : -window.innerWidth
  })

  const { x: createClientX } = useSpring({
    x: isVisibleCreate ? 0 : -window.innerWidth
  })

  const value = {
    casosView: {
      isVisible: casosView.isVisible,
      casosViewX,
      id: casosView.id,
      setCasosView,
      title: casosView.title
    },
    createClient: {
      createClientX,
      setIsVisibleCreate,
      isVisibleCreate
    }
  }

  const onCloseCasosView = (): void => {
    setCasosView((prev) => {
      return {
        ...prev,
        isVisible: false,
        id: undefined
      }
    })
  }

  const onCloseClienteCreate = (): void => {
    setIsVisibleCreate(false)
  }

  return (
    <AnimationHandlerContext.Provider value={value}>
      {children}
      <animated.div
        className="content"
        style={{
          transform: casosViewX.to((x) => `translate3d(${x * -1}px, 0, 0)`),
          position: 'fixed',
          display: casosView.isVisible ? 'block' : 'none',
          top: 0,
          left: 0,
          zIndex: 50,
          width: '100vw',
          height: '100vh',
          padding: '20px',
          backgroundColor: theme.color.surface.container.default,
          overflowY: 'scroll'
        }}
      >
        <Col>
          <Navbar
            title={
              tiposAjuda.find((e) => e.tipo === casosView.title)?.desc ?? ''
            }
            onClose={onCloseCasosView}
          />
          <CasosView searchContext={searchContext} />
        </Col>
      </animated.div>
      <animated.div
        className="content"
        style={{
          transform: createClientX.to((x) => `translate3d(${x * -1}px, 0, 0)`),
          position: 'fixed',
          display: isVisibleCreate ? 'block' : 'none',
          top: 0,
          left: 0,
          zIndex: 50,
          width: '100vw',
          height: '100vh',
          padding: '20px',
          backgroundColor: theme.color.surface.container.default,
          overflowY: 'scroll'
        }}
      >
        <Col
          style={{
            position: 'relative',
            backgroundColor: theme.color.surface.container.default
          }}
        >
          <Navbar
            title="Adicionar novo cliente"
            onClose={onCloseClienteCreate}
          />
          <CreateClientForm form={newClientForm} />
          <ClientFooter form={newClientForm} onClose={onCloseClienteCreate} />
        </Col>
      </animated.div>
    </AnimationHandlerContext.Provider>
  )
}
