import {
  JobsSelect,
  Label,
  Radio,
  message,
  Col,
  FieldInput,
  Form,
  Row,
  Text,
  Select,
  Card,
  Divider,
  type FormInstance,
  usePrevTheme,
  LocalLoading,
  useWidth
} from '@prev-front/ui-components'
import React, { useContext, useEffect, useState } from 'react'
import {
  type NewClient,
  createClient,
  type Profissao,
  getEnderecoByCep,
  getJobs
} from '../../services'
import { InfoCircleOutlined } from '@ant-design/icons'
import { ApiError } from '@prev-front/api-adapter'
import { ajustHifen, cleanObject, escolaridade, estados } from '../../utils'
import { NotificationContext } from '../../context/NotificationContext'
import { AnimationHandlerContext } from '../../context/AnimationHandlerContext'
import moment from 'moment'

interface Props {
  form: FormInstance<NewClient>
}

export const CreateClientForm = (props: Props): JSX.Element => {
  const { form } = props
  const [selectedJob, setSelectedJob] = useState<Profissao>()
  const { theme, darkMode } = usePrevTheme()
  const { api } = useContext(NotificationContext)
  const { createClient: createClientModal } = useContext(
    AnimationHandlerContext
  )
  const today = moment(Date.now()).format('YYYY-MM-DD')
  const [mainPhoneMask, setMainPhoneMask] = useState('(99) 99999-9999')
  const [secondaryPhoneMask, setSecondaryPhoneMask] =
    useState('(99) 99999-9999')
  const [cellPhoneMask, setCellPhoneMask] = useState('(99) 99999-9999')
  const [loading, setLoading] = useState(false)
  const width = useWidth()

  useEffect(() => {
      handleMasks()
  }, [])

  const fetchAddress = async (cep: string): Promise<void> => {
    try {
      const onlyNumbersCep = cep.replace(/\D/g, '')
      const endereco = await getEnderecoByCep(onlyNumbersCep)
      endereco.numero && delete endereco.numero
      props.form.setFieldsValue({ endereco })
    } catch (err) {}
  }

  const handleSubmit = async (): Promise<void> => {
    try {
      const dataValues = form.getFieldsValue()
      const withJobs = {
        ...dataValues,
        profissoes: [selectedJob]
      }
      const data = cleanObject(withJobs)
      setLoading(true)
      await createClient(data)
      form.resetFields()
      api.success({
        message: 'Cliente cadastrado com sucesso!',
        placement: 'bottomRight'
      })
      setLoading(false)
      createClientModal.setIsVisibleCreate(false)
    } catch (err) {
      setLoading(false)
      api.error({
        message: err.message,
        placement: 'topRight'
      })
      if (!(err instanceof ApiError))
        void message.error('Algo deu errado, tente novamente mais tarde')
    }
  }

  const handleMasks = (): void => {
    setMainPhoneMask('(99) 99999-9999')
    setSecondaryPhoneMask('(99) 99999-9999')
    setCellPhoneMask('(99) 99999-9999')
  }

  if (loading)
    return (
      <LocalLoading
        display={loading}
        style={{ height: '100vh', overflow: 'hidden' }}
      />
    )

  return (
    <Row
      style={{
        justifyContent: 'center',
        padding: `0px 20px 20px ${width <= 1200 ? '20px' : '130px'}`
      }}
      gutter={24}
    >
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={18}
        xxl={18}
        style={{ width: '100%', position: 'relative' }}
      >
        <Col span={24} style={{ marginBottom: '22px' }}>
          <Text type="paragraph"  style={{ marginBottom: '10px' }}>
            Dados pessoais do cliente
          </Text>
          <Row align="middle" style={{ gap: '5px' }}>
            <InfoCircleOutlined style={{ color: theme.color.icon.danger }} />
            <Text size="default">Campos de preenchimento obrigatório</Text>
          </Row>
        </Col>
        <Form
          name="newClientForm"
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
          scrollToFirstError={{
            behavior: 'smooth',
            block: 'center'
          }}
          onFinish={handleSubmit}
          form={form}
        >
          <Row style={{ justifyContent: 'space-between' }} gutter={12}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="nome"
                rules={[
                  {
                    required: true,
                    message: 'Insira o nome do cliente'
                  }
                ]}
              >
                <FieldInput
                  label="Nome completo"
                  required
                  name="nome"
                  placeholder="Digite o nome do cliente"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item
                name="cpf"
                validateDebounce={500}
                rules={[
                  {
                    required: true,
                    message: 'Insira o CPF do cliente'
                  },
                  {
                    pattern: /^(?:\d{3}\.?\d{3}\.?\d{3}-?\d{2}|\d{11})$/,
                    message: 'CPF inválido'
                  }
                ]}
              >
                <FieldInput
                  mask="999.999.999-99"
                  required
                  label="CPF"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
              <Form.Item
                name="sexo"
                required
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório'
                  }
                ]}
              >
                  <Row style={{ marginBottom: "13px"}}>
                    <Text color={theme.color.text.subtle} size="default">
                      Sexo{' '}
                      <InfoCircleOutlined
                        style={{
                          color: darkMode
                            ? theme.color.text.subtle
                            : theme.color.icon.brand
                        }}
                      />
                    </Text>
                  </Row>
                  <Row>
                    <Form.Item name="sexo">
                      <Radio.Group>
                        <Radio value="MASCULINO">Masculino</Radio>
                        <Radio value="FEMININO">Feminino</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{ justifyContent: 'space-between', paddingTop: '20px' }}
            gutter={12}
          >
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item
                name="dataDeNascimento"
                rules={[
                  {
                    required: true,
                    message: 'A data de nascimento é obrigatória'
                  }
                ]}
                style={{ margin: 0, padding: 0 }}
              >
                <FieldInput
                  required
                  max={today}
                  label="Nascimento"
                  type="date"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
              <Form.Item
                name="estadoCivil"
                style={{ margin: 0, padding: 0 }}
                initialValue={'SOLTEIRO'}
              >
                <Select
                  label="Estado civil"
                  options={[
                    {
                      label: 'Solteiro',
                      value: 'SOLTEIRO'
                    },
                    {
                      label: 'Casado',
                      value: 'CASADO'
                    },
                    {
                      label: 'Viuvo',
                      value: 'VIUVO'
                    },
                    {
                      label: 'Divorciado',
                      value: 'DIVORCIADO'
                    },
                    {
                      label: 'Separado',
                      value: 'SEPARADO'
                    }
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
            <Form.Item>
            <JobsSelect
                showLabel={true}
                selected={selectedJob}
                setSelected={setSelectedJob}
                getJobs={getJobs}
              />
            </Form.Item>
            </Col>
          </Row>
          <Text style={{ margin: '20px 0' }}>Dados de contato do cliente</Text>
          <Row style={{ justifyContent: 'space-between' }} gutter={12}>
            <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
              <Form.Item
                style={{ margin: 0, padding: 0, width: '100%' }}
                name="email"
                validateDebounce={500}
                rules={[
                  {
                    type: 'email',
                    message: 'Email inválido'
                  }
                ]}
              >
                <FieldInput
                  label="Email"
                  placeholder="Digite o email do cliente"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Form.Item
                validateDebounce={500}
                name="celular"
                rules={[
                  {
                    message: 'Número inválido',
                    min: 14
                  }
                ]}
              >
                <FieldInput
                  style={{ width: '100%' }}
                  mask={cellPhoneMask}
                  onKeyDown={(event) => {
                    const { currentTarget } = event
                    if (
                      currentTarget.value.length > 14 &&
                      event.key === 'Backspace'
                    ) {
                      setTimeout(() => {
                        setCellPhoneMask('(99) 9999-9999')
                        props.form.setFieldValue(
                          'celular',
                          ajustHifen(currentTarget.value)
                        )
                      }, 100)
                    }
                  }}
                  onBeforeInput={(event) => {
                    if (event.currentTarget.value.length >= 14) {
                      setCellPhoneMask('(99) 99999-9999')
                    } else {
                      setCellPhoneMask('(99) 9999-9999')
                    }
                  }}
                  label="Celular"
                  placeholder="Digite seu telefone"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Form.Item
                validateDebounce={500}
                name="telefonePrincipal"
                rules={[
                  {
                    message: 'Número inválido',
                    min: 14
                  }
                ]}
              >
                <FieldInput
                  style={{ width: '100%' }}
                  mask={mainPhoneMask}
                  onKeyDown={(event) => {
                    const { currentTarget } = event
                    if (
                      currentTarget.value.length > 14 &&
                      event.key === 'Backspace'
                    ) {
                      setTimeout(() => {
                        setMainPhoneMask('(99) 9999-9999')
                        props.form.setFieldValue(
                          'telefonePrincipal',
                          ajustHifen(currentTarget.value)
                        )
                      }, 100)
                    }
                  }}
                  onBeforeInput={(event) => {
                    if (event.currentTarget.value.length >= 14) {
                      setMainPhoneMask('(99) 99999-9999')
                    } else {
                      setMainPhoneMask('(99) 9999-9999')
                    }
                  }}
                  label="Telefone"
                  placeholder="Digite seu telefone"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Form.Item
                validateDebounce={500}
                name="telefoneSecundario"
                rules={[
                  {
                    message: 'Número inválido',
                    min: 14
                  }
                ]}
              >
                <FieldInput
                  style={{ width: '100%' }}
                  mask={secondaryPhoneMask}
                  onKeyDown={(event) => {
                    const { currentTarget } = event
                    if (
                      currentTarget.value.length > 14 &&
                      event.key === 'Backspace'
                    ) {
                      setTimeout(() => {
                        setSecondaryPhoneMask('(99) 9999-9999')
                        props.form.setFieldValue(
                          'telefoneSecundario',
                          ajustHifen(currentTarget.value)
                        )
                      }, 100)
                    }
                  }}
                  onBeforeInput={(event) => {
                    if (event.currentTarget.value.length >= 14) {
                      setSecondaryPhoneMask('(99) 99999-9999')
                    } else {
                      setSecondaryPhoneMask('(99) 9999-9999')
                    }
                  }}
                  label="Telefone secundário"
                  placeholder="Digite o telefone"
                />
              </Form.Item>
            </Col>
          </Row>
          <Text style={{ margin: '20px 0' }}>Endereço do cliente</Text>
          <Row style={{ justifyContent: 'space-between' }} gutter={12}>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
            <Form.Item name={['endereco', 'cep']}>
                    <FieldInput mask='99999-999' onBlur={(event) => {
                      if (event?.target?.value.length >= 9) {
                        void fetchAddress(event.target.value)
                      }
                    }} label='CEP' placeholder='00000-000' />
            </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item name={['endereco', 'endereco']}>
                <FieldInput
                  label="Rua/avenida"
                  placeholder="Digite o nome da rua ou avenida"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item name={['endereco', 'numero']}>
                <FieldInput label="Número" placeholder="Digite o número" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item name={['endereco', 'complemento']}>
                <FieldInput label="Complemento" placeholder="apt/sala/bloco" />
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{ justifyContent: 'space-between', marginTop: '20px' }}
            gutter={12}
          >
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item name={['endereco', 'bairro']}>
                <FieldInput label="Bairro" placeholder="Digite o bairro" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item name={['endereco', 'cidade']}>
                <FieldInput label="Cidade" placeholder="Digite a cidade" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item name={['endereco', 'uf']}>
                <Select
                  options={estados}
                  label="Estado"
                  placeholder="Selecione seu estado"
                />
              </Form.Item>
            </Col>
          </Row>
          <Text style={{ margin: '20px 0' }}>Outros dados e documentos</Text>
          <Row style={{ justifyContent: 'space-between' }} gutter={12}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item
                name="escolaridade"
                initialValue={escolaridade[0].value}
              >
                <Select
                  label="Escolaridade"
                  placeholder="Selecione a escolaridade"
                  dropdownStyle={{ zIndex: 9999999 }}
                  options={escolaridade.map((e) => ({
                    label: e.desc,
                    value: e.value
                  }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item
                name="rg"
                rules={[
                  { required: false, min: 8, message: 'Formato inválido' }
                ]}
              >
                <FieldInput
                  label="RG"
                  placeholder="00.000.000-0"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item name="cnh">
                <FieldInput label="CNH" placeholder="000000000000" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item
                name="ctps"
                rules={[
                  { min: 16, required: false, message: 'Formato inválido' }
                ]}
              >
                <FieldInput
                  label="CTPS"
                  placeholder="000.000000.00-00"
                  mask="999.999999.99-99"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{ justifyContent: 'space-between', marginTop: '40px' }}
            gutter={12}
          >
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="nit"
                rules={[
                  { min: 14, required: false, message: 'Formato inválido' }
                ]}
              >
                <FieldInput
                  label="NIT"
                  mask="999.99999.99-9"
                  placeholder="000.00000.00-0"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="nitSecundario"
                rules={[
                  { min: 14, required: false, message: 'Formato inválido' }
                ]}
              >
                <FieldInput
                  label="NIT Secundário"
                  mask="999.99999.99-9"
                  placeholder="000.00000.00-0"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item name="dataDeObito">
                <FieldInput
                  label="Data de óbito"
                  type="date"
                  max={today}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col
        xs={0}
        sm={0}
        md={0}
        lg={0}
        xl={6}
        xxl={6}
        style={{ height: '100%', width: '100%' }}
      >
        <Card style={{ width: '100%', height: '100%' }}>
          <Text type="paragraph">
            Por que é importante o cadastro completo do meu cliente?
          </Text>
          <Divider />
          <Text type="paragraph">
            Prev é a plataforma previdenciária mais moderna e mais segura do
            mercado!
          </Text>
          <Text>
            Aqui seus dados são protegidos, além disso não usaremos seu email
            para enviar promoções, usaremos somente para envio dos emails
            transacionais e de atualizações da plataforma.
          </Text>
        </Card>
      </Col>
    </Row>
  )
}
